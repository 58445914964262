import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import PageHeader from "../components/common/PageHeader"
import BlogCard from "../components/common/BlogCard"

import Layout from "../components/layout"
import RecipeGrid from "../components/recipes/RecipeGrid"
import Seo from "../components/seo"

const Wrapper = styled.div``

export const query = graphql`
  query BlogsQuery {
    contentfulBlogs {
      blogs {
        slug
        id
        blogCategory {
          title
        }
        blogTitle
        blogFeaturedImage {
          gatsbyImage(width: 560, placeholder: BLURRED)
        }
      }
    }
  }
`

function divideArray(objects: Array<any>, K: number, N: number) {
  let ans: any = []
  let temp: any = []
  for (let i = 0; i < N; i++) {
    temp.push(objects[i])
    if ((i + 1) % K == 0) {
      ans.push(temp)
      temp = []
    }
  }

  if (temp.length != 0) {
    ans.push(temp)
  }

  return ans
}

export function Head() {
  return <Seo title={"Blogs"} />
}

export default function Recipes({ data }) {
  const blogs = data.contentfulBlogs.blogs
  const dividedGrid = divideArray(blogs, 9, blogs.length)

  return (
    <Layout>
      <PageHeader
        header="Blog"
        subheader="One place for high-powered plants, brain-boosting botanicals, distinctive cocktails, and other news from the Drømme team. Read on. Drink Drømme."
        body={null}
        cta="View Blogs"
        onCtaClick={() => window.scrollBy(0, 265)}
      />

      {dividedGrid.map(grid => (
        <RecipeGrid>
          {grid.map(blog => (
            <BlogCard key={blog.id} blog={blog} inline />
          ))}
        </RecipeGrid>
      ))}
    </Layout>
  )
}
