import React from "react"

import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"

const Wrapper = styled.div`
  padding: 40px 24px;
  display: grid;

  grid-row-gap: 16px;

  @media ${breakpoints.tablet} {
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 40px;

    max-width: 1440px;
    margin: 0 auto;

    position: relative;

    &:last-of-type:after {
      content: "";

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);

      height: 1px;
      width: 100vw;

      background: ${colors.creamLight};
    }

    > a {
      grid-column: span 3;
    }

    > a:nth-child(1) {
      grid-column: span 4;
    }

    > a:nth-child(6) {
      grid-column: span 4;
    }

    > a:nth-child(8) {
      grid-column: span 4;
    }
  }
`

export default function RecipeGrid({ children }) {
  return <Wrapper>{children}</Wrapper>
}
