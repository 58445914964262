import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import typography from "../../styles/typography"
import { Header2, BodyMedium, Body } from "../typography"

const Wrapper = styled.div`
  display: flex;
  color: ${colors.creamLight};
  flex-direction: column;

  padding: 40px 24px;

  max-width: 1440px;
  margin: 0 auto;

  box-sizing: border-box;

  ${Header2} {
    margin-bottom: 24px;
  }

  ${BodyMedium} {
    margin-bottom: 16px;
  }

  position: relative;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
    padding: 40px;

    > ${Header2} {
      flex: 0 0 45%;
    }

    > div {
      flex: 0 0 55%;
    }
  }
`
const TextButton = styled.button`
  ${typography.metaLarge.desktop}

  appearance: none;
  background: none;
  border: 0;

  padding: 0;
  margin-top: 24px;

  text-transform: uppercase;
  color: ${colors.orangeDark};

  cursor: pointer;
`

export default function PageHeader({
  header,
  subheader,
  body,
  cta,
  onCtaClick,
}) {
  return (
    <Wrapper>
      <Header2>{header}</Header2>
      <div>
        <BodyMedium>{subheader}</BodyMedium>
        <Body>{body}</Body>
        {cta ? <TextButton onClick={onCtaClick}>{cta} ↓</TextButton>: ""}
      </div>
    </Wrapper>
  )
}
